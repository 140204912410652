import React from 'react'
import Head from '../components/head'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import TextHighlight from '../components/textHighlight'
import '../styles/index.css'

//check to swap with stateless component
class IndexPage extends React.Component {
  render() {
    return (
      <>
        <div className="monodon-element-container">
          <svg
            aria-labelledby="monodonElementTitle monodonElementDesc"
            className="monodon-element"
            enable-background="new 0 0 3665 3555"
            preserveAspectRatio="xMidYMin slice"
            role="img"
            version="1.1"
            viewBox="0 0 3665 3555"
            x="0px"
            xmlns="http://www.w3.org/2000/svg"
            y="0px"
          >
            <title id="monodonElementTitle">monodon background element</title>
            <desc id="jrmLogoDesc">
              just a decorative element in the background
            </desc>
            <path
              id="Path_619"
              fill="none"
              stroke="#F7F7F7"
              stroke-dasharray="6,6"
              d="M1940.4,799.3c198.3,294.1,120.8,693.2-173.3,891.6
          s-693.2,120.8-891.6-173.3S0.5,0.5,0.5,0.5l1356,0L1940.4,799.3z"
            />
            <circle
              fill="none"
              stroke="#F7F7F7"
              cx="1387.8"
              cy="1144.8"
              r="370.5"
            />
            <path
              id="Path_618"
              fill="none"
              stroke="#F7F7F7"
              d="M2453.5,3483.5L726.3,192.9l2939.1,3212.9l-682,149.2L2453.5,3483.5z"
            />
          </svg>
        </div>
        <div class="index-wrapper">
          <Layout page="Work">
            <Head title="Home" />
            <section className="index-content content-gutter">
              <h1>Helping businesses build great brands.</h1>
              <p class="super-paragraph">
                <TextHighlight>Designing and developing. </TextHighlight>
                Displaying your story, values and offerings in a clear and
                unique manner. And providing you with a global standard of tools
                that help communicate them to others.
              </p>
              <Link className="button-two dark-style" to="/work">
                Let's See Some Work
              </Link>
            </section>
          </Layout>
        </div>
      </>
    )
  }
}

export default IndexPage
